// **  Initial State

import stringHelper from "../../../util/helpers/StringHelper";

let current_user = stringHelper.GetUserInfo()
const initialState = {
  userData: current_user ? current_user :{}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    default:
      return state
  }
}

export default authReducer
