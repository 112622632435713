import BaseApiService from "../BaseApiService";

class LookupsApiService extends BaseApiService {
  constructor() {
    super('lookup');
  }
   GetLookupsByType = (type) =>{
    let params = {};
    let _filter = [{
      operator: 'and',
      conditions: [
          {
              field: 'type',
              operator: 'eq',
              value: type
          }
      ]
    }];
    params.filter = _filter; 
    return this.find(params)
  }

}

const lookupsApiService = new LookupsApiService();

export default lookupsApiService;