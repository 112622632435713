// **  Initial State

const initialState = {
    dataAgent: {agent_id : null, agent_name : null},
    agentStatus: {agent_id : null, status : 'connecting', date_start : null},
    agentSession : {agent_id : null, session : null}
}

const callReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AGENT_ID':
            return {
                ...state,
                dataAgent: action.data,
            }
        case 'AGENT_STATUS':
            return {
                ...state,
                agentStatus : action.data
            }
        case 'AGENT_SESSION':
            return {
                ...state,
                agentSession : action.data
            }
        default:
            return state
    }
}

export default callReducer
