// ** React Imports
import {useState, createContext, useContext} from 'react'
import { IntlContext } from '@src/utility/context/Internationalization'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesDe from '@assets/data/locales/de.json'
import messagesFr from '@assets/data/locales/fr.json'
import messagesPt from '@assets/data/locales/pt.json'

// ** User Language Data

import stringHelper from "../../util/helpers/StringHelper";

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn },
  de: { ...messagesDe },
  fr: { ...messagesFr },
  pt: { ...messagesPt }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
    const intlContext = useContext(IntlContext);

    // ** States
  const [locale, setLocale] = useState(intlContext?.locale || stringHelper.GetUserInfo().lang);
  const [messages, setMessages] = useState(menuMessages[intlContext?.locale || stringHelper.GetUserInfo().lang])
  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }
  return (
      <Context.Provider value={{ locale, switchLanguage }}>
        <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={intlContext?.locale || stringHelper.GetUserInfo().lang}>
          {children}
        </IntlProvider>
      </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
