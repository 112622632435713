import BaseApiService from "../BaseApiService";
import {apiUrl} from "../../configs/site.config";

class AgentLogEvenetsApiService extends BaseApiService {
  constructor() {
    super('agent_log_event');
  }

  getLastEvent(data) {
    return this.setHeaderToken().post(apiUrl + "/agent_log_event/getLastEvent",data);
  }
}

const agentLogEvenetsApiService = new AgentLogEvenetsApiService();

export default agentLogEvenetsApiService;