import lookupsApiService from "../../util/services/LookupsApiService";
import {toast} from "react-toastify";
import React, {Fragment} from "react";
import Avatar from "../../@core/components/avatar";
import {CheckCircle, Info} from "react-feather";
import agentLogEventsApiService from "../services/agentLogEventsApiService";
import {tz} from "../../configs/site.config";
import moment from "moment-timezone";

moment.tz.add('America/Los_Angeles|PST PDT|80 70|0101|1Lzm0 1zb0 Op0');

const CryptoJS = require("crypto-js")

class StringHelper extends React.Component {

    constructor(props) {
        super(props);
        this.refCdr = React.createRef();
        this.refClone = React.createRef();
        this.refImport = React.createRef();
    }

    GenerateAccountInfos() {
        let account_number = this.generatenumber(12);
        let account_login = account_number;
        let account_password = this.generatestring(12);
        let sip_password = this.generatestring(12);
        let default_name = this.generatenumber(12);
        return {
            account_number: account_number,
            account_login: account_login,
            account_password: account_password,
            sip_password: sip_password,
            default_name: default_name
        }
    }

    formatNumbers(number) {
        let numToString = number.toString();
        if (numToString.length === 1) return `00${numToString}`;
        else if (numToString.length === 2) return `0${numToString}`;
        return `${numToString}`;
    }

    checkNotState(value){
        return !!!value || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0);
    }
    GetAccountName(account) {
        return account?.company?.concat("(", account?.first_name, " ", account?.last_name, ")");
    }

    GetCallFileName(callfile) {
        let UserName = '';
        if (!!!callfile?.first_name && !!!callfile?.last_name) {
            return ''
        }
        if (callfile?.first_name) {
            UserName += callfile?.first_name
        }
        if (callfile?.last_name) {
            UserName += ' ' + callfile?.last_name
        }
        return UserName
    }

    setCryptedLocalStorage(key, value) {
        let StringifyText = JSON.stringify(value);
        let CryptedText = this.encrypt(StringifyText);
        localStorage.setItem(key, CryptedText)
    }

    getCrpytedLocalStorage(key) {
        if (!localStorage.getItem(key)) return {};
        return JSON.parse(this.decrypt(localStorage.getItem(key)));
    }

    convertDate(date) {
        let newDate = moment(new Date(date)).tz('Europe/Paris').format("YYYY-MM-DD HH:mm:ss")
        let indexDot = newDate.indexOf('.');
        let indexZ = newDate.indexOf('Z');
        if (indexDot !== -1 && indexZ !== -1) {
            let supDate = newDate.slice(indexDot, indexZ + 1);
            return newDate.replace('T', ' ').replace(supDate, '');
        } else {
            return newDate;
        }
    }

    GetUserInfo() {
        if (!localStorage.getItem('currentuser')) return {};
        return JSON.parse(this.decrypt(localStorage.getItem('currentuser')));
    }

    FirstLetterRole(role, GroupType = null) {
        if (GroupType === 'G') {
            return ''
        }
        switch (role) {
            case  'admin' :
                return '(A)';
            case  'superadmin' :
                return '(S)';
            case  'user' :
                return '(U)';
            default :
                return ''
        }
    }

    encrypt(text) {
        let password = "jE2s8rtuHA9q6FRZ"
        if (!text) return ''
        return CryptoJS.AES.encrypt(text, password).toString();
    }

    decrypt(text) {
        let password = "jE2s8rtuHA9q6FRZ"
        if (!text) return ''
        const bytes = CryptoJS.AES.decrypt(text, password);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    is_uniqCode(all_codes, n) {
        return new Promise((resolve, reject) => {
            let randomString = '';
            let randomStringInt = Math.random().toString(36).substr(2, n);
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for (let i = 0; i < n; i++) {
                randomString += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            let is_exist = all_codes.findIndex(item => item === randomStringInt)
            if (is_exist !== -1) {
                resolve({code: randomString.toUpperCase(), is_unique: false});
            } else {
                resolve({code: randomString.toUpperCase(), is_unique: true});
            }
            resolve(randomString);
        })
    }

    generatestring(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    generatePassword() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const digits = '0123456789';
        let password = '';

        // Generate 3 random characters
        for (let i = 0; i < 3; i++) {
            password += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        // Generate 3 random digits
        for (let i = 0; i < 3; i++) {
            password += digits.charAt(Math.floor(Math.random() * digits.length));
        }

        return password;
    }

    generatenumber(length) {
        let result = '';
        let characters = '0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    getLookupsUser(current_path, user) {
        return new Promise((resolve, reject) => {
            if (user.role_id !== null) {
                user?.list_permission?.map(item => {
                    if (current_path.includes(item.permission_route) && (item.lookups.findIndex(element => element.key === 'list') !== -1)) {
                        user['lookups'] = item.lookups
                    }
                })
                resolve(user)
            } else {
                user['lookups'] = [];
                lookupsApiService.GetLookupsByType("PERMISSION").then((res) => {
                    let idx = 0;
                    let dataLookups = res?.data?.data;
                    dataLookups.map((item) => {
                        user['lookups'].push({
                            key: item.key
                        })
                        if (idx < dataLookups.length - 1) {
                            idx++
                        } else {
                            resolve(user)
                        }
                    })
                })
            }
        })


    }

    checkIp(ip, normal = false) {
        const regexValidIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
        const IP = ip.substring(0, ip.indexOf('/'));
        let lastThreeChars = ip.slice(-3);
        if (normal) {
            let check = regexValidIP.test(ip)
            return check
        } else {
            let validIP = regexValidIP.test(IP)
            let res = {
                success: validIP && lastThreeChars === "/32",
                message: lastThreeChars !== "/32" ? 'must-end-/32' : (!validIP ? 'invalid-ip' : 'vide')
            }
            return res
        }

    }

    checkExistanceKeyValueInArrayOfObjects(key, value, list) {
        return list.some(l => l[key] === value)
    }


    notifyStartImporting = (message) => {
        localStorage.setItem('toaster_is_active_importing', true);
        this.refImport.current = toast(this.InfoToast({title: message}), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeButton: false // Remove the closeButton
        })
    }
    removeToasterImporting = () => {
        localStorage.removeItem('toaster_is_active_importing')
    }
    updateToastImporting = (message, seconds) => {
        let toaster_is_active = localStorage.getItem('toaster_is_active_importing');
        if (!!!toaster_is_active) {
            localStorage.setItem('toaster_is_active_importing', true);
            this.notifyStartImporting();
        }
        toast.update(this.refImport.current, {
            render: this.InfoToast({title: message}),
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: false,
            closeButton: false
        })
    }
    startedImporting = (message) => {
        localStorage.removeItem('toaster_is_active_importing')
        toast.update(this.refImport.current, {
            render: this.SuccessToast({title: message}),
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: false,
            closeButton: false
        })
    }


    notifyStartCloning = (message) => {
        localStorage.setItem('toaster_is_active_cloning', true);
        this.refClone.current = toast(this.InfoToast({title: message}), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeButton: false // Remove the closeButton
        })
    }
    removeToasterCloning = () => {
        localStorage.removeItem('toaster_is_active_cloning')
    }
    updateToastCloning = (message, seconds) => {
        let toaster_is_active = localStorage.getItem('toaster_is_active_cloning');
        if (!!!toaster_is_active) {
            localStorage.setItem('toaster_is_active_cloning', true);
            this.notifyStartCloning();
        }
        toast.update(this.refClone.current, {
            render: this.InfoToast({title: message}),
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: false,
            closeButton: false
        })
    }
    startedCloning = (message) => {
        localStorage.removeItem('toaster_is_active_cloning')
        toast.update(this.refClone.current, {
            render: this.SuccessToast({title: message}),
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: true,
            autoClose: false,
            closeButton: false
        })
    }


    notifyStartGetData = () => {
        localStorage.setItem('toaster_is_active', true);
        this.refCdr.current = toast(this.InfoToast({title: 'Loading data after few seconds ..'}), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeButton: false // Remove the closeButton
        })
    }
    updateToast = () => {
        let toaster_is_active = localStorage.getItem('toaster_is_active');
        if (!!!toaster_is_active) {
            localStorage.setItem('toaster_is_active', true);
            this.notifyStartGetData();
        }
        toast.update(this.refCdr.current, {
            render: this.SuccessToast({title: "Data loaded successfully !"}),
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: false,
            autoClose: 12000,
            closeButton: null
        })
    }

    InfoToast = ({title}) => (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color='info' icon={<Info size={12}/>}/>
                    <h6 className='text-info ml-50 mb-0'>{title}</h6>
                </div>
                <small className='text-muted'/>
            </div>
        </Fragment>
    )

    SuccessToast = ({title}) => (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color='success' icon={<CheckCircle size={12}/>}/>
                    <h6 className='text-success ml-50 mb-0'>{title}</h6>
                </div>
                <small className='text-muted'/>
            </div>
        </Fragment>
    )
    getDateXDaysAgo = (date1, length = 2, reminder = false, currentDate = new Date(moment().tz(tz).format("YYYY-MM-DD HH:mm:ss"))) => {
        let TimeToMonth = 2419200;
        let TimeToDay = 86400;
        let TimeToHour = 3600;
        let m1 = new Date(date1)
        let Diff = (currentDate.getTime() - m1.getTime()) / 1000;
        if (reminder) {
            Diff = (m1.getTime() - currentDate.getTime()) / 1000;
        }
        let DiffMonths = Math.trunc(Diff / TimeToMonth);
        Diff = DiffMonths !== 0 ? Diff % TimeToMonth : Diff
        let DiffDays = Math.trunc(Diff / TimeToDay);
        Diff = DiffDays !== 0 ? Diff % TimeToDay : Diff
        let DiffHours = Math.trunc(Diff / TimeToHour);
        Diff = DiffHours !== 0 ? Diff % TimeToHour : Diff
        let DiffMinutes = Math.trunc(Diff / 60);
        Diff = DiffMinutes !== 0 ? Diff % 60 : Diff
        let DiffSeconds = Math.trunc(Diff);
        let ReturnDiffMonth = DiffMonths !== 0 ? (DiffMonths === 1 ? DiffMonths.toString() : DiffMonths.toString() + ' Months ') : null;
        let ReturnDiffDay = DiffDays !== 0 ? (DiffDays === 1 ? DiffDays.toString() + ' Day ' : DiffDays.toString() + ' Days ') : null;
        let ReturnDiffHour = DiffHours !== 0 ? (DiffHours === 1 ? DiffHours.toString() + ' Hour ' : DiffHours.toString() + ' Hours ') : null;
        let ReturnDiffMin = DiffMinutes !== 0 ? (DiffMinutes === 1 ? DiffMinutes.toString() + ' Minute ' : DiffMinutes.toString() + ' Minutes ') : null;
        let ReturnDiffSec = DiffSeconds !== 0 ? (DiffSeconds === 1 ? DiffSeconds.toString() + ' Second ' : DiffSeconds.toString() + ' Seconds ') : null;
        let idx = 0;
        let dateToString = '';
        if (ReturnDiffMonth && idx < length) {

            dateToString += ReturnDiffMonth;
            idx++;
        }
        if (ReturnDiffDay && idx < length) {
            dateToString += idx === 1 ? ' and ' : ''
            dateToString += ReturnDiffDay;
            idx++;
        }
        if (ReturnDiffHour && idx < length) {
            dateToString += idx === 1 ? ' and ' : ''
            dateToString += ReturnDiffHour;
            idx++;
        }
        if (ReturnDiffMin && idx < length) {
            dateToString += idx === 1 ? ' and ' : ''
            dateToString += ReturnDiffMin;
            idx++;
        }
        if (ReturnDiffSec && idx < length) {
            dateToString += idx === 1 ? ' and ' : ''
            dateToString += ReturnDiffSec;
            idx++;
        }
        return dateToString;
    }
    getLastCrmStatus = (user_id) => {
        return new Promise((resolve, reject) => {
            let params = {
                sortDir: "DESC",
                sortBy: "agent_log_event_id",
                limit: 1
            };
            params.filter = [
                {
                    operator: "and",
                    conditions: [
                        {
                            field: "user_id",
                            operator: "eq",
                            value: user_id,
                        },
                    ],
                },
            ];
            agentLogEventsApiService.find(params)
                .then(res => {
                    let logs = res?.data?.data;
                    if (logs && logs.length !== 0) {
                        resolve({
                            action: logs[0].action_name,
                            start_at: logs[0].start_at,
                            finish_at: logs[0].finish_at
                        })
                    } else {
                        resolve('logged-out')
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })

    }

}

const stringHelper = new StringHelper();

export default stringHelper;

